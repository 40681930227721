import React from "react";
import { useState, useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Iconify from "../Iconify";
import CustomButton from "../CustomButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: 500 },
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const Timer = ({ expiryTimestamp }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => setOpen(true),
  });
  useEffect(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 600);
    restart(time);
  }, []);

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Iconify
                  icon="mdi:timer-sand-complete"
                  sx={{ width: 50, height: 50, color:'gainsboro' }}
                />
              </Box>
              <Typography
                align="center"
                fontSize={14}
                id="transition-modal-description"
                sx={{ mt: 2 }}
              >
                Nous avons dû interrompre ta reservation car elle a pris plus de
                10 minutes. <br />
                Nous ne pouvons la retenir plus longtemps car nous voulons être
                équitables pour tous nos clients. <br />
                Tu peux cependant relancer la reséservation.
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <CustomButton type={'secondaire'}  buttonText={'Revenir aux billets'} onClick={() => {
                    window.location.reload();
                  }}/>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
      <Typography fontSize={14} textAlign="center" paddingBottom={1}>
        Temps restant {minutes === 0 ? "00" : minutes}:
        {seconds === 0 ? "00" : seconds}
      </Typography>
    </>
  );
};

export default Timer;
