import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Footer from "../Components/Footer";
import { UserAuth } from "../context/AuthContext";
import Iconify from "../Components/Iconify";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "../Components/profil/ticket";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { Variables } from "../context/VariableContext";
import EventCard from "../Components/EventCard";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Organisateur() {
  const [loading, setLoading] = useState(false);
  const { openLogin, setOpenLogin } = Variables();
  const { orgID } = useParams();
  const [events, setEvents] = React.useState([]);
  const [pastEvents, setPastEvents] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [nom, setNom] = React.useState("");
  const [num, setNum] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [socialMedia, setSocialMedia] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [isFollow, setIsFollow] = React.useState(false);
  const [infoUser, setInfoUser] = React.useState();
  const [banner, setBanner] = React.useState();
  const [logo, setLogo] = React.useState();
  // Links------------------------
  const [webLink, setWeb] = React.useState();
  const [fbLink, setFb] = React.useState();
  const [instaLink, setInsta] = React.useState();
  const [xLink, setX] = React.useState();
  const [youLink, setYou] = React.useState();
  const [tiktokLink, setTiktok] = React.useState();
  const [msgerLink, setMsger] = React.useState();
  const [wha, setWha] = React.useState();
  const { user, logOut } = UserAuth();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createPastEventList = (doc) => {
    setPastEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const today = new Date();
  const today1 = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        if (orgID) {
          const u1 = await getDoc(doc(db, "organisations", `${orgID}`));

          // setInfoUser(userRef);
          if (u1.exists()) {
            console.log(u1.data());
            setNom(u1.data().nomOrg);
            setNum(u1.data().contact);
            setLocation(u1.data().email);
            setBanner(u1.data().bannerVisuel);
            setLogo(u1.data().idVisuel);
            setWebsite(u1.data().urlPhoto);
          }

          const cc = await getDoc(
            doc(db, `organisations/${orgID}/coordonnee`, `${orgID}`)
          );
          if (cc.exists()) {
            setLocation(`${cc.data().city}, ${cc.data().country}`);
            setNum(cc.data().tel);
          }

          const links = await getDoc(
            doc(db, `organisations/${orgID}/links`, `${orgID}`)
          );
          if (links.exists()) {
            setWeb(links.data().web);
            setWha(links.data().what);
            setInsta(links.data().insta);
            setYou(links.data().youtube);
            setFb(links.data().facebook);
            setMsger(links.data().messenger);
            setTiktok(links.data().tiktok);
            setX(links.data().x);
          }
          getEvents();

        }
      } catch (error) {
        console.log(error.msg);
      }
      setLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const u1 = await getDoc(doc(db, `users/${user.uid}/followings`, orgID));
        if (u1.exists()) {
          setIsFollow(true);
        } else {
          setIsFollow(false);
        }
      } catch (error) {
        console.log(error.msg);
      }
      setLoading(false);
    })();
  }, [user, refreh]);

  React.useEffect(() => {
    (async () => {
      try {
        if (user) {
          const us = await getDoc(
            doc(db, `users`, user.uid)
          );
          if (us.exists()) {
            console.log(us.data())
            setInfoUser(us);
          }
        }
      } catch (error) {
        console.log(error.msg);
      }
      setLoading(false);
    })();
  }, [user]);

  // follow
  const follow = async () => {
    if (user) {
      var exist;
      console.log(user.email);
      console.log(infoUser);
      setLoading(true);
      try {
        const u1 = await getDoc(doc(db, `users/${user.uid}/followings`, orgID));
        if (u1.exists()) {
          exist = 1;
        } else {
          exist = -1;
        }

        if (exist === -1) {
          // abonnement
          const u2 = await getDoc(doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, user.email));
          if (u2.exists()) {
            await updateDoc(
              doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, user.email),
              {
                nom: infoUser.data().nom,
                prenom: infoUser.data().prenom,
                push: true,
                newslettres: true,
              }
            );
          } else {
            await setDoc(
              doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, user.email),
              {
                uid: user.uid,
                nom: infoUser.data().nom,
                prenom: infoUser.data().prenom,
                push: true,
                newslettres: true,
              }
            );
          }


          await setDoc(doc(db, `users/${user.uid}/followings/${orgID}`), {
            push: true,
            newslettres: true,
          });
        } else {
          // desabonnement
          await updateDoc(
            doc(db, `organisations/${orgID}/crm/${orgID}/contacts`, user.email),
            {
              uid: user.uid,
              nom: infoUser.data().nom,
              prenom: infoUser.data().prenom,
              push: false,
              newslettres: false,
            }
          );

          await deleteDoc(doc(db, `users/${user.uid}/followings`, orgID));
        }

        // setConfirm(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setRefresh(!refreh);
    } else {
      setOpenLogin(true);
    }
  };

  const getEvents = async () => {
    setEvents([]);
    setPastEvents([]);
    try {
      const q = query(
        collection(db, "evenement"),
        where("orgId", "==", orgID),
        where("status", "in", [1, 2]),
        orderBy("dateDebut", "asc")
      );
      const querySnapshot = await getDocs(q);
      console.log(" print------------------------------", querySnapshot.size);
      querySnapshot.forEach((element) => {
        // sort list

        if (
          new Date(handleTimestamp(element.data().dateFin)) > today1 &&
          element.data().status === 1
        ) {
          console.log(" print------------------------------");
          console.log(element.data());
          setEvents((events) => [...events, element]);
        } else if (
          new Date(handleTimestamp(element.data().dateFin)) < today1 ||
          element.data().status === 2
        ) {
          setPastEvents((events) => [...events, element]);
        }
      });
    } catch (error) { }
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split(".");
    return tmp[0];
  };

  return (
    <>
      <Container sx={{ paddingX: { xs: 2, sm: 5 }, paddingY: 10 }}>
        {banner ? (
          <div
            style={{
              backgroundImage: `url(${banner})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "250px",
              display: "flex",
              padding: 5,
              marginBottom: 10,
              alignItems: "center",
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
              top: 50,
              zIndex: -1,
            }}
          />
        ) : (
          <div
            style={{
              backgroundColor: "transparent",
              height: "50px",
              display: "flex",
              padding: 5,
              marginBottom: 10,
              alignItems: "center",
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
              top: 50,
              zIndex: -1,
            }}
          />
        )}

        <Stack spacing={1} paddingBottom={10}>
          <Box paddingTop={15} display="flex" justifyContent={{ xs: 'center', sm: 'start' }}>
            <Avatar src={logo} sx={{ width: 150, height: 150 }}></Avatar>
          </Box>

          <Stack sx={{ width: '100%' }} spacing={1} justifyContent={'center'} alignItems={'center'}>
            <Stack sx={{ width: '100%' }} direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "550",
                }}
                align="start"
              >
                {nom}
              </Typography>
              <Box display="flex" justifyContent="start">
                {isFollow ? (
                  <Button
                    size="medium"
                    sx={{
                      textTransform: "initial",
                      border: "2px solid #5E17EB",
                      backgroundColor: "white",
                      color: "#5E17EB",
                      height: 40,
                      borderRadius: "7px",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#5E17EB",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      follow();
                    }}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                      >
                        <Typography fontSize={14}>Abonner</Typography>{" "}
                        <Iconify
                          icon="flat-color-icons:ok"
                          sx={{ height: 20, width: 20 }}
                        />
                      </Stack>
                    )}
                  </Button>
                ) : (
                  <Button
                    size="medium"
                    sx={{
                      textTransform: "initial",
                      border: "2px solid #5E17EB",
                      backgroundColor: "#5E17EB",
                      color: "white",
                      height: 40,
                      borderRadius: "7px",
                      "&:hover": {
                        backgroundColor: "#5E17EB",
                        color: "white",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      follow();
                    }}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Typography fontSize={14}>S'abonner</Typography>
                    )}
                  </Button>
                )}
              </Box>
            </Stack>
            <Stack spacing={1} sx={{ width: '100%' }} direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} alignItems={'center'}>
              <Stack>
                <Stack direction="row" justifyContent={{ xs: 'center', sm: 'start' }} spacing={2}>
                  <Iconify
                    icon="mdi:telephone-outline"
                    sx={{ width: 20, height: 20 }}
                  />
                  <Typography>{num}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="start" spacing={2}>
                  <Iconify
                    icon="humbleicons:location"
                    sx={{ width: 24, height: 24 }}
                  />
                  <Typography>{location}</Typography>
                </Stack>
                {
                  website ? <Stack direction="row" justifyContent="start" spacing={2}>
                    <Iconify icon="carbon:link" sx={{ width: 24, height: 24 }} />
                    <Typography>{website}</Typography>
                  </Stack> : null
                }
              </Stack>
              <Stack direction="row" justifyContent="end" spacing={1}>
                <IconButton>
                  <Iconify
                    icon="logos:facebook"
                    sx={{ width: 24, height: 24 }}
                  />
                </IconButton>
                <IconButton><Iconify
                  icon="logos:tiktok-icon"
                  sx={{ width: 24, height: 24 }}
                /></IconButton>

                <IconButton><Iconify
                  icon="skill-icons:instagram"
                  sx={{ width: 24, height: 24 }}
                /></IconButton>

                <IconButton><Iconify
                  icon="mingcute:social-x-line"
                  sx={{ width: 24, height: 24 }}
                /></IconButton>

                <IconButton><Iconify
                  icon="logos:whatsapp-icon"
                  sx={{ width: 24, height: 24 }}
                /></IconButton>

                <IconButton> <Iconify
                  icon="logos:messenger"
                  sx={{ width: 24, height: 24 }}
                /></IconButton>

              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: { xs: "100%" } }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Evenements à venir" {...a11yProps(0)} />
                <Tab label="Evenements passés" {...a11yProps(1)} />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                ></Box>
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {loading ? (
                <Grid container spacing={5} paddingY={2}>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container rowSpacing={5} paddingY={2}>
                  {events.map((property, index) => (
                    <Grid item xs={12} md={4} sx={{ height: `100%` }}>
                      <EventCard
                        ev={property}
                        key={property.id}
                        img={property.data().idVisuel}
                        name={property.data().nomEvent}
                        lieu={property.data().lieu_event}
                        city={property.data().city}
                        type={property.data().type}
                        debut={property.data().dateDebut}
                        organisateur={nom}
                        eventID={property.id}
                        sx={{ height: `100%` }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {loading ? (
                <Grid container spacing={5} paddingY={2}>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container rowSpacing={5} paddingY={2}>
                  {pastEvents.map((property, index) => (
                    <Grid item xs={12} md={4} sx={{ height: `100%` }}>
                      <EventCard
                        ev={property}
                        key={property.id}
                        img={property.data().idVisuel}
                        name={property.data().nomEvent}
                        lieu={property.data().lieu_event}
                        city={property.data().city}
                        type={property.data().type}
                        debut={property.data().dateDebut}
                        organisateur={nom}
                        eventID={property.id}
                        sx={{ height: `100%` }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </TabPanel>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
