import {
  Card,
  Box,
  styled,
  Typography,
  CardMedia,
  Grid,
  Stack,
  Divider,
} from "@mui/material";
import React from "react";
import bedroomsIcon from "../media/bedroomsIcon.png";
import bathroomsIcon from "../media/bathroomsIcon.png";
import spaceIcon from "../media/spaceIcon.png";
import { useNavigate } from "react-router-dom";
import Iconify from "./Iconify";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { handleFirebaseTimestamp } from "./api/api";

const ResearchItem = ({
  img,
  price,
  name,
  eventID,
  debut,
  fin,
  lieu,
  type,
  organisateur,
  seanceActive,
  city,
  ev,
}) => {
  const EventBox = styled(Card)(({ theme }) => ({
    borderRadius: "7px",
   
    cursor: "pointer",
    "&:hover": {
      boxShadow: "2px 2px 15px #dcdcde",
    },
    backgroundColor: "#fff",
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  }));

  const ImgBox = styled("img")(({ theme }) => ({
    width: 200,
    height: 200,
    borderRadius: "0 7px 7px 0",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      width: 130,
      height: 200,
    },
  }));

  const ImgContainer = styled(Box)(() => ({
    //width: "100%",
    //height:"200"
  }));
  const navigate = useNavigate();
  const handleTimestamp = (e) => {
    if (e) {
      // const date = e.toDate();
      const tmp = e.toJSON().split(".");
      return tmp[0];
    } else {
      return "24/02/2013";
    }
  };

  return (
    <EventBox>
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            paddingX: { xs: 1, md: "1rem" },
          }}
        >
          <Box>
            <Typography
              noWrap
              fontSize={18}
              variant="body2"
              sx={{
                fontWeight: "700",
                textOverflow: "ellipsis",
                width: { xs: 200, md: 300, xl: 400 },
              }}
            >
              {name}
            </Typography>
            <Stack direction="column" spacing={1}>
              <Typography fontSize={13} fontWeight="550" color="#999999">
                {organisateur}
              </Typography>
              <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                { <Typography color="#5E17EB" fontSize={16}>
                  {" "}
                  {debut && format(new Date(handleTimestamp(handleFirebaseTimestamp(debut))), 'dd MMM yyyy', { locale: fr })} {debut && format(new Date(handleTimestamp(handleFirebaseTimestamp(debut))), 'HH', { locale: fr })}h{debut && format(new Date(handleTimestamp(handleFirebaseTimestamp(debut))), 'mm', { locale: fr })} - {ev && ev.data.seanceActive === 1 && format(new Date(handleTimestamp(handleFirebaseTimestamp(fin))), 'dd MMM yyyy', { locale: fr })} {ev && format(new Date(handleTimestamp(handleFirebaseTimestamp(fin))), 'HH', { locale: fr })}h{ev && format(new Date(handleTimestamp(handleFirebaseTimestamp(fin))), 'mm', { locale: fr })}
                </Typography> }
              </Stack>
              <Typography fontSize={15}>{ev.data.locationType === 1 ? 'Evénement en ligne' : `${city} - ${lieu}`} | {ev && ev.data.minPrice !== 0 ? ` ${ev.data.minPrice} CFA` : 'Gratuit'}</Typography>
              <Grid item xs={4} sm={3}>
                <Box
                  sx={{
                    border: " 1px solid #999999",
                    borderRadius: "20px",
                    bgcolor: "black",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack direction="row">
                    <Typography variant="caption" padding={0.5} color="white">
                      {type}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImgBox src={img} alt="logo" />
        </Box>
      </Stack>
    </EventBox>
  );
};

export default ResearchItem;
