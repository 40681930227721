import axios from "axios";
import Cookies from "js-cookie";

// Base URL de votre fonction Cloud
const BASE_URL = "https://us-central1-event-86cbf.cloudfunctions.net/getFilteredEvents";

// Fonction pour obtenir les événements filtrés


export const fetchFilteredEvents = async (filters, page) => {
  const baseUrl = BASE_URL; // Replace with your actual function URL
  const params = new URLSearchParams({
    search: filters?.search || null,
    location: filters?.location || null,
    price: filters?.price || null,
    date: filters?.date || null, // e.g., 'today', 'tomorrow', 'weekend'
    category: filters?.category || null,
    page: page || 1,
  });

  try {
    const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/getFilteredEvents', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error calling the Cloud Function:", error);
    return null;
  }
};

export const fetchFilteredEvents1 = async ({
  search = "",
  location = "",
  price,
  dateFilter = "",
  category = "",
  page = 1,
  limit = 10,
}) => {
  try {
    const response = await axios.get(BASE_URL, {
      params: { search, location, price, dateFilter, category, page, limit },
    });
    return response.data; // Retourne les données du serveur
  } catch (error) {
    console.error("Erreur lors de la récupération des événements :", error);
    throw error;
  }
};

// check code promo
export const checkPromoCode = async (
  eventID, discountField, ticketsList
) => {
  try {
    const oldValue = Cookies.get(`discount_code`)
    console.log('test')
    console.log(oldValue)
    console.log('test')
    if (oldValue !== 'undefined' && oldValue !== discountField) {
      const discount_using_code = Cookies.get(`discount_using_code`)
      console.log(discount_using_code)
      // lancer la requête de clean
      if (discount_using_code !== 'undefined') {
        console.log('Condition valide pour lancer la fonciton')
        await launchCleanExpiredCodePromoUsedTask(discount_using_code)
      }
    }
    const response = await axios.post("https://us-central1-event-86cbf.cloudfunctions.net/checkPromoCode", {
      code: discountField,
      idEvent: eventID,
      ticketsList,
      oldValue: oldValue !== 'undefined' ? oldValue : null,
    });
    console.log(response.data)
    Cookies.set(`discount_code`, discountField, { expires: 1 });
    Cookies.set(`discount_using_code`, response.data.discount.usingId, { expires: 1 });
    return response.data; // Retourne les données du serveur
  } catch (error) {
    console.error("Erreur lors de la récupération des événements :", error);
    throw error;
  }
};

export const launchCleanExpiredCodePromoUsedTask = async (
  discount_using_code
) => {
  try {
    console.log('lancement de fonction launchCleanExpiredCodePromoUsedTask')
    console.log(discount_using_code)
    if (discount_using_code) {
      await axios.post("https://us-central1-event-86cbf.cloudfunctions.net/CleanExpiredCodePromoUsed", {
        id: discount_using_code !== 'undefined' ? discount_using_code : null,
      });
      Cookies.remove(`discount_code`);
      Cookies.remove(`discount_using_code`);
    }

  } catch (error) {
    console.error("Erreur lors de launchCleanExpiredCodePromoUsedTask :", error);
    throw error;
  }
};

export const launchCleanExpiredOrder = async (
  billId
) => {
  try {
    console.log('lancement de fonction launchCleanExpiredOrder')
    console.log(billId)
    if (billId) {
      await axios.post("https://us-central1-event-86cbf.cloudfunctions.net/cleanExpiredOrder", {
        billId: billId !== 'undefined' ? billId : null,
      });
      Cookies.remove(`billId`);
    }

  } catch (error) {
    console.error("Erreur lors de launchCleanExpiredCodePromoUsedTask :", error);
    throw error;
  }
};

export const handleFirebaseTimestamp = (
  firebaseTimestamp
) => {
  try {

    const timestampInMillis = firebaseTimestamp._seconds * 1000 + firebaseTimestamp._nanoseconds / 1000000;
    const date = new Date(timestampInMillis);
    return date; // Retourne les données du serveur
  } catch (error) {
    console.error("Erreur lors de la récupération des événements :", error);
    throw error;
  }
};